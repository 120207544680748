import { HTMLAttributes, ReactNode, useCallback, useMemo } from 'react'
import { ScreenRoutePath, useRouterLocation, useRouterNavigate } from 'src/App/router/hooks'
import BookmarkFilledIcon from 'src/_shared/components/_icons/BookmarkFilledIcon'
import { ConnectorPowerType, FilterType, LocationFilterModalType } from 'src/_shared/enums/filter'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import { useLocationFilters } from 'src/_shared/hooks/useLocationFilters'
import { useLocationFiltersModalContext } from 'src/_shared/hooks/useLocationFiltersModalContext'
import { useMiniProgramContext } from 'src/_shared/hooks/useMiniProgramContext'
import { classNames } from 'src/_shared/utils/elements'

import BatteryFilledIcon from '../_icons/BatteryFilledIcon'
import ChevronDownIcon from '../_icons/ChevronDownIcon'
import QuickFilterButton from './components/QuickFilterButton'

const QUICK_FILTER_OPTIONS: {
	key: FilterType
	label: string
	icon: ReactNode
}[] = [
	{
		key: FilterType.All,
		label: 'All',
		icon: null
	},
	{
		key: FilterType.Cpo,
		label: 'CPO',
		icon: <ChevronDownIcon className="mr-1.5 mt-0.5 w-2" />
	},
	{
		key: FilterType.Favourites,
		label: 'Favourites',
		icon: <BookmarkFilledIcon className="mr-1.5 w-2" />
	},
	{
		key: FilterType.Ac,
		label: ConnectorPowerType.Ac,
		icon: <BatteryFilledIcon className="mr-0.5 w-3.5" />
	},
	{
		key: FilterType.Dc,
		label: ConnectorPowerType.Dc,
		icon: <BatteryFilledIcon className="mr-0.5 w-3.5" />
	}
]

export type QuickFilterButtonsProps = Omit<HTMLAttributes<HTMLDivElement>, 'children'>

const QuickFilterButtons = ({ className, ...props }: QuickFilterButtonsProps): JSX.Element => {
	const { isAuthenticated } = useAuthContext()

	const { locationFilters, setFilter, setFilters } = useLocationFilters()

	const { showModal } = useLocationFiltersModalContext()

	const { authenticate: authenticateViaMiniProgram } = useMiniProgramContext()

	const { pathname } = useRouterLocation()

	const navigate = useRouterNavigate()

	const isFilterOptionActiveMap = useMemo((): Record<FilterType, boolean> => {
		return {
			[FilterType.Ac]: locationFilters.powerType === ConnectorPowerType.Ac,
			[FilterType.Dc]: locationFilters.powerType === ConnectorPowerType.Dc,
			[FilterType.Favourites]: locationFilters.favouritesOnly,
			[FilterType.Cpo]:
				locationFilters.cpoEntityCodes !== null && locationFilters.cpoEntityCodes.length > 0,
			[FilterType.All]:
				locationFilters.powerType === null &&
				locationFilters.cpoEntityCodes === null &&
				!locationFilters.favouritesOnly
		}
	}, [locationFilters])

	const handleCpoFilterOnClick = useCallback((): void => {
		showModal(LocationFilterModalType.Cpo)
	}, [showModal])

	const handleSelectFilterOption = useCallback(
		(filterOption: FilterType): void => {
			if (filterOption === FilterType.Ac || filterOption === FilterType.Dc) {
				const newPowerType =
					filterOption === FilterType.Ac ? ConnectorPowerType.Ac : ConnectorPowerType.Dc
				if (locationFilters.powerType === newPowerType) {
					setFilter('powerType', null)
				} else {
					setFilter('powerType', newPowerType)
				}
			} else if (filterOption === FilterType.All) {
				setFilters(null)
			} else if (filterOption === FilterType.Favourites) {
				if (isAuthenticated) {
					setFilter('favouritesOnly', !locationFilters.favouritesOnly)
				} else {
					if (authenticateViaMiniProgram) {
						authenticateViaMiniProgram()
					} else {
						navigate({
							pathname: ScreenRoutePath.AccountLogin,
							search: `redirect=${pathname}`
						})
					}
				}
			} else {
				// Else, CPO filter is handled separately in the FilterModal
				handleCpoFilterOnClick()
			}
		},
		[
			isAuthenticated,
			locationFilters,
			pathname,
			authenticateViaMiniProgram,
			handleCpoFilterOnClick,
			navigate,
			setFilter,
			setFilters
		]
	)

	return (
		<div className={classNames('flex items-center justify-between', className)} {...props}>
			{QUICK_FILTER_OPTIONS.map(
				(option): JSX.Element => (
					<QuickFilterButton
						{...option}
						key={option.key}
						filterType={option.key}
						onClick={(): void => {
							handleSelectFilterOption(option.key)
						}}
						isActive={isFilterOptionActiveMap[option.key]}
					/>
				)
			)}
		</div>
	)
}

export default QuickFilterButtons
