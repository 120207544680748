import { useQueryClient } from '@tanstack/react-query'
import { memo, MouseEvent, useCallback, useMemo } from 'react'
import { ScreenRoutePath, useRouterLocation, useRouterNavigate } from 'src/App/router/hooks'
import BookmarkFilledIcon from 'src/_shared/components/_icons/BookmarkFilledIcon'
import DirectionsIcon from 'src/_shared/components/_icons/DirectionsIcon'
import ParkingIcon from 'src/_shared/components/_icons/ParkingIcon'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import { useMiniProgramContext } from 'src/_shared/hooks/useMiniProgramContext'
import {
	useAddUserFavouriteLocationMutation,
	useRemoveUserFavouriteLocationMutation
} from 'src/_shared/mutations/user'
import {
	ROOT_USER_QUERY_KEY,
	UserQueryKey,
	useUserFavouriteLocationsSummaryQuery
} from 'src/_shared/queries/user'
import { OmniLocation } from 'src/_shared/types/omni/location'
import { classNames } from 'src/_shared/utils/elements'
import { shareLocationUrl } from 'src/_shared/utils/redirect'

import IconButton from './components/IconButton'

interface LocationHeaderActionsProps {
	location: OmniLocation
	hasDirectionsIcon?: boolean
	handleCarParkRatesModalOpen: () => void
	handleFavouriteActionError?: (message: string) => void
}

const LocationHeaderActions = ({
	location,
	hasDirectionsIcon = false,
	handleCarParkRatesModalOpen,
	handleFavouriteActionError
}: LocationHeaderActionsProps): JSX.Element | null => {
	const locationUid = location.uid ?? ''

	const { isAuthenticated } = useAuthContext()

	const { authenticate: authenticateViaMiniProgram } = useMiniProgramContext()

	const queryClient = useQueryClient()

	const { pathname } = useRouterLocation()

	const navigate = useRouterNavigate()

	const handleSettledInvalidation = async (): Promise<void> => {
		await queryClient.invalidateQueries({
			queryKey: [ROOT_USER_QUERY_KEY, UserQueryKey.UserFavouritesLocationSummary]
		})
		await queryClient.invalidateQueries({
			queryKey: [ROOT_USER_QUERY_KEY, UserQueryKey.UserFavouritesInfiniteLocations]
		})
	}

	const { data: favouriteLocations = [] } = useUserFavouriteLocationsSummaryQuery(
		{},
		{
			staleTime: Infinity, // Cache indefinitely
			refetchOnMount: 'always'
		}
	)

	const { isPending: isAddFavouriteLocationPending, mutate: addFavouriteLocation } =
		useAddUserFavouriteLocationMutation({
			onError: (error) => {
				handleFavouriteActionError?.(
					error.response?.data.message ?? "Couldn't add location to favourites"
				)
			},
			onSettled: handleSettledInvalidation
		})

	const { isPending: isRemoveFavouriteLocationPending, mutate: removeFavouriteLocation } =
		useRemoveUserFavouriteLocationMutation({
			onError: (error) => {
				handleFavouriteActionError?.(
					error.response?.data.message ?? "Couldn't remove location from favourites list"
				)
			},
			onSettled: handleSettledInvalidation
		})

	const isLocationFavourite = useMemo((): boolean => {
		return favouriteLocations.some(
			(favouriteLocation) => favouriteLocation.locationUid === location.uid
		)
	}, [favouriteLocations, location])

	const handleClickDirections = useCallback(
		(event: MouseEvent) => {
			event.stopPropagation()
			shareLocationUrl(location)
		},
		[location]
	)

	const handleAddFavouriteLocation = useCallback((): void => {
		addFavouriteLocation({ locationUid })
	}, [addFavouriteLocation, locationUid])

	const handleRemoveFavouriteLocation = useCallback((): void => {
		removeFavouriteLocation({
			locationUids: [locationUid]
		})
	}, [locationUid, removeFavouriteLocation])

	const handleFavouriteLocationClick = useCallback(
		(event: MouseEvent): void => {
			event.stopPropagation()
			if (isAuthenticated) {
				if (isAddFavouriteLocationPending || isRemoveFavouriteLocationPending) {
					return
				}
				if (isLocationFavourite) {
					handleRemoveFavouriteLocation()
				} else {
					handleAddFavouriteLocation()
				}
			} else {
				if (authenticateViaMiniProgram) {
					authenticateViaMiniProgram()
				} else {
					navigate({
						pathname: ScreenRoutePath.AccountLogin,
						search: `redirect=${pathname}`
					})
				}
			}
		},
		[
			isAuthenticated,
			isAddFavouriteLocationPending,
			isRemoveFavouriteLocationPending,
			isLocationFavourite,
			pathname,
			authenticateViaMiniProgram,
			handleRemoveFavouriteLocation,
			handleAddFavouriteLocation,
			navigate
		]
	)

	return (
		<div className="flex flex-row space-x-2">
			<IconButton
				isLoading={isAddFavouriteLocationPending || isRemoveFavouriteLocationPending}
				onClick={handleFavouriteLocationClick}
				className={classNames(
					isLocationFavourite && !isAddFavouriteLocationPending && !isRemoveFavouriteLocationPending
						? 'bg-alert-100'
						: 'bg-grayscale-200'
				)}
			>
				<BookmarkFilledIcon
					className={classNames(
						'h-5 w-5',
						isLocationFavourite ? 'text-alert-300' : 'text-grayscale-600'
					)}
					fill={isLocationFavourite ? undefined : 'none'}
				/>
			</IconButton>
			<IconButton
				onClick={(event): void => {
					event.stopPropagation()
					handleCarParkRatesModalOpen()
				}}
				className="bg-info-100"
			>
				<ParkingIcon className="h-5 w-auto text-info-300" />
			</IconButton>
			{hasDirectionsIcon && (
				<IconButton onClick={handleClickDirections} className="bg-primary-800">
					<DirectionsIcon className="h-5 w-auto text-grayscale-100" />
				</IconButton>
			)}
		</div>
	)
}

const MemoisedLocationHeaderActions = memo(LocationHeaderActions)

export default MemoisedLocationHeaderActions
