import { useCallback, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { ScreenRoutePath, useQueryParams, useRouterNavigate } from 'src/App/router/hooks'
import EnterOtpViewComponent from 'src/_shared/components/EnterOtpView'
import Notice from 'src/_shared/components/Notice'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import {
	useUserMobileNumberOtpRequestMutation,
	useUserRegisterMutation
} from 'src/_shared/mutations/auth'

import useCountdownTimer from '../../../_shared/hooks/useCountdownTimer'
import { FormFieldKey } from '../enums'
import { AccountRegistrationScreenCommonViewProps } from '../types'

type EnterOtpViewProps = AccountRegistrationScreenCommonViewProps

const EnterOtpView = ({
	formValues,
	setFormValues,
	shouldSendOtp,
	setShouldSendOtp
}: EnterOtpViewProps): JSX.Element => {
	const { countryCode, email, licensePlateNumber, mobileNumber, name, otp, password } = formValues

	const { isAuthenticated, setAccessToken } = useAuthContext()

	const [{ redirect = '' }] = useQueryParams<{ redirect?: string }>()

	const [seconds, resetCountdownTimer] = useCountdownTimer()

	const navigate = useRouterNavigate()

	const {
		error: otpResendError,
		isError: isOtpResendError,
		isPending: isRequestOtpPending,
		mutateAsync: requestOtp,
		reset: resetUserOtpRequestMutation
	} = useUserMobileNumberOtpRequestMutation()

	const {
		error: registerUserError,
		isError: isRegisterUserError,
		isPending: isRegisterUserPending,
		isSuccess: isRegisterUserSuccess,
		mutateAsync: registerUser,
		reset: resetUserRegisterMutation
	} = useUserRegisterMutation()

	const errorMessage =
		registerUserError?.response?.data.message ?? otpResendError?.response?.data.message

	const handleOtpChange = useCallback(
		(value: string): void => {
			setFormValues(
				(values): Record<FormFieldKey, string> => ({
					...values,
					[FormFieldKey.Otp]: value
				})
			)
			if (isRegisterUserError && value !== otp) {
				resetUserRegisterMutation()
			}
		},
		[isRegisterUserError, otp, resetUserRegisterMutation, setFormValues]
	)

	const handleRequestOtpClick = useCallback((): void => {
		void requestOtp(
			{
				mobileNumber: countryCode + mobileNumber
			},
			{
				onSuccess: (): void => {
					resetUserOtpRequestMutation()
					resetCountdownTimer()
				}
			}
		)
	}, [countryCode, mobileNumber, requestOtp, resetCountdownTimer, resetUserOtpRequestMutation])

	const handleOtpFilled = useCallback(
		(value: string): void => {
			void registerUser(
				{
					name,
					mobileNumber: countryCode + mobileNumber,
					password,
					email,
					licensePlateNumber,
					otp: value
				},
				{
					onSuccess: ({ token: accessToken }): void => {
						setAccessToken(accessToken)
					}
				}
			)
		},
		[
			countryCode,
			email,
			licensePlateNumber,
			mobileNumber,
			name,
			password,
			registerUser,
			setAccessToken
		]
	)

	/**
	 * In the event that we came from the register screen,
	 * check if we should send an otp immediately upon entering this page.
	 */
	useEffect(() => {
		if (!isAuthenticated && shouldSendOtp) {
			setShouldSendOtp(false)
			handleRequestOtpClick()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthenticated, shouldSendOtp])

	if (isAuthenticated) {
		const redirectPath = redirect ? (redirect as ScreenRoutePath) : ScreenRoutePath.Map
		if (isRegisterUserSuccess) {
			return (
				<Notice
					type="success"
					header="Your account has been activated"
					buttonProps={{
						children: 'Continue',
						onClick: (): void => {
							resetUserRegisterMutation()
							navigate(redirectPath, { replace: true })
						}
					}}
				/>
			)
		}
		return <Navigate to={redirectPath} replace={true} />
	}

	return (
		<EnterOtpViewComponent
			dataTestIdPrefix="ars"
			otpRecipient={
				<span
					data-testid="ars-eov-text-mobile-number"
					className="body-1-semibold mt-1 block text-primary-800"
				>
					{countryCode} {mobileNumber}
				</span>
			}
			secondsCountdown={seconds}
			isOtpVerifyPending={isRegisterUserPending}
			isRequestOtpPending={isRequestOtpPending}
			inputOtpProps={{
				id: FormFieldKey.Otp,
				name: FormFieldKey.Otp,
				value: otp,
				disabled: isRegisterUserPending,
				error: isRegisterUserError || isOtpResendError,
				description: errorMessage,
				autoFocus: true,
				onChange: handleOtpChange,
				onFilled: handleOtpFilled
			}}
			onRequestOtpClick={handleRequestOtpClick}
		/>
	)
}

export default EnterOtpView
