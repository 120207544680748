import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { ScreenRoutePath, useQueryParams, useRouterNavigate } from 'src/App/router/hooks'
import AccountScreenHeader from 'src/_shared/components/AccountScreenHeader'
import Button from 'src/_shared/components/Button'
import Checkbox from 'src/_shared/components/Checkbox'
import Input from 'src/_shared/components/Input'
import InputPassword from 'src/_shared/components/InputPassword'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import SelectCountry from 'src/_shared/components/SelectCountry'
import TelephoneIcon from 'src/_shared/components/_icons/TelephoneIcon'
import UnlockIcon from 'src/_shared/components/_icons/UnlockIcon'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import { useUserLoginMutation } from 'src/_shared/mutations/auth'
import { delay } from 'src/_shared/utils/time'
import isMobilePhone from 'validator/lib/isMobilePhone'

import SplashScreenView from './components/SplashScreenView'
import { FormFieldKey } from './enums'

const AccountLoginScreen = (): JSX.Element => {
	const [formValues, setFormValues] = useState<Record<FormFieldKey, string>>({
		[FormFieldKey.CountryCode]: '+65',
		[FormFieldKey.MobileNumber]: '',
		[FormFieldKey.Password]: '',
		[FormFieldKey.RememberMe]: String(true)
	})

	const { isAuthenticated, setAccessToken } = useAuthContext()

	const [{ redirect = '' }] = useQueryParams<{ redirect?: string }>()

	const navigate = useRouterNavigate()

	const {
		error: loginError,
		isError: isLoginError,
		isPending: isLoginPending,
		isSuccess: isLoginSuccess,
		mutateAsync: login,
		reset: resetUserLoginMutation
	} = useUserLoginMutation()

	const redirectPath = redirect ? (redirect as ScreenRoutePath) : ScreenRoutePath.Map

	const errorMessage = useMemo((): string | undefined => {
		if (isLoginError) {
			switch (loginError.response?.status) {
				case 401:
					return 'Incorrect mobile number or password. Please try again.'
				default:
					return 'Oops! Something went wrong'
			}
		}
	}, [isLoginError, loginError])

	const isFormValid = useMemo((): boolean => {
		const mobileNumber =
			formValues[FormFieldKey.CountryCode] + formValues[FormFieldKey.MobileNumber]
		return isMobilePhone(mobileNumber) && formValues[FormFieldKey.Password].length > 0
	}, [formValues])

	const handleFormChange = useCallback(
		(key: FormFieldKey) =>
			(event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>): void => {
				const formattedValue = ((): string => {
					const formValue = event.currentTarget.value
					switch (key) {
						case FormFieldKey.MobileNumber:
							return formValue.replace(/[^0-9]/, '')
						default:
							return formValue.replace(/\s/g, '')
					}
				})()
				setFormValues(
					(values): Record<FormFieldKey, string> => ({
						...values,
						[key]: formattedValue
					})
				)
				if (isLoginError) {
					resetUserLoginMutation()
				}
			},
		[isLoginError, resetUserLoginMutation]
	)

	const handleRememberMeChange = useCallback((checked: boolean): void => {
		setFormValues(
			(values): Record<FormFieldKey, string> => ({
				...values,
				[FormFieldKey.RememberMe]: String(checked)
			})
		)
	}, [])

	const handleLoginClick = useCallback(
		(event: FormEvent<HTMLFormElement>): void => {
			// Prevent default form submission behaviour.
			event.preventDefault()

			if (isFormValid) {
				const mobileNumber =
					formValues[FormFieldKey.CountryCode] + formValues[FormFieldKey.MobileNumber]
				const password = formValues[FormFieldKey.Password]
				const rememberMe = formValues[FormFieldKey.RememberMe] === 'true'
				void login(
					{
						mobileNumber,
						password,
						rememberMe
					},
					{
						onSuccess: ({ token: accessToken }): void => {
							const handleSuccess = async (): Promise<void> => {
								setAccessToken(accessToken)
								await delay(2500) // Delay briefly to show the splash screen.
								resetUserLoginMutation()
								navigate(redirectPath, { replace: true })
							}
							void handleSuccess()
						}
					}
				)
			}
		},
		[formValues, isFormValid, redirectPath, login, resetUserLoginMutation, navigate, setAccessToken]
	)

	if (isAuthenticated) {
		if (isLoginSuccess) {
			return <SplashScreenView />
		}
		return <Navigate to={redirectPath} replace={true} />
	}
	return (
		<ScreenContainer
			contentViewProps={{
				className: 'px-5 pt-6'
			}}
			hideBottomBar
		>
			<form
				data-testid="als-form-login"
				className="flex flex-grow flex-col"
				onSubmit={handleLoginClick}
			>
				<div className="flex-grow space-y-5">
					<AccountScreenHeader
						title={
							<>
								Stay Charged Anywhere,
								<br />
								Anytime
							</>
						}
					/>
					<div className="flex flex-col">
						<Input
							dataTestIdPrefix="als-mobile-number"
							className="mb-3"
							autoComplete="on"
							type="tel"
							placeholder="Enter your mobile number"
							id={FormFieldKey.MobileNumber}
							name={FormFieldKey.MobileNumber}
							value={formValues[FormFieldKey.MobileNumber]}
							onChange={handleFormChange(FormFieldKey.MobileNumber)}
							error={isLoginError}
							disabled={isLoginPending}
							startAdornment={
								<div className="flex flex-row items-center space-x-3 py-1 pl-1">
									<TelephoneIcon className="h-5 w-5 text-typography-secondary" />
									<SelectCountry
										dataTestIdPrefix="als"
										id={FormFieldKey.CountryCode}
										name={FormFieldKey.CountryCode}
										value={formValues[FormFieldKey.CountryCode]}
										onChange={handleFormChange(FormFieldKey.CountryCode)}
										disabled={isLoginPending}
									/>
								</div>
							}
						/>
						<InputPassword
							data-testid="als-input-password"
							className="mb-5"
							autoComplete="on"
							placeholder="Enter your password"
							id={FormFieldKey.Password}
							name={FormFieldKey.Password}
							value={formValues[FormFieldKey.Password]}
							onChange={handleFormChange(FormFieldKey.Password)}
							description={errorMessage}
							error={isLoginError}
							disabled={isLoginPending}
							startAdornment={
								<div className="p-1">
									<UnlockIcon className="h-5 w-5 text-typography-secondary" />
								</div>
							}
						/>
						<div className="flex flex-row items-center justify-between">
							<Checkbox
								dataTestIdPrefix="als-remember-me"
								label="Remember me"
								id={FormFieldKey.RememberMe}
								name={FormFieldKey.RememberMe}
								checked={formValues[FormFieldKey.RememberMe] === 'true'}
								onChange={handleRememberMeChange}
							/>
							<Link
								data-testid="als-link-forgot-password"
								className="body-1-normal text-typography-primary"
								to={{
									pathname: ScreenRoutePath.AccountForgotPassword,
									search: location.search
								}}
							>
								Forgot password?
							</Link>
						</div>
					</div>
				</div>
				<div className="flex flex-col space-y-6 py-6">
					<Button
						data-testid="als-btn-login"
						variant="primary"
						disabled={!isFormValid || isLoginPending}
						loading={isLoginPending}
					>
						Login
					</Button>
					<p className="body-1-normal text-center text-typography-secondary">
						Don&apos;t have an account?{' '}
						<Link
							data-testid="als-link-register"
							className="body-1-semibold text-typography-primary"
							to={{
								pathname: ScreenRoutePath.AccountRegistration,
								search: location.search
							}}
						>
							Register
						</Link>
					</p>
				</div>
			</form>
		</ScreenContainer>
	)
}

export default AccountLoginScreen
